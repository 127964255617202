import React from 'react';
import './header.css';
import logo from '../../images/logo.jpg'; // Adjust the path as needed

function Header() {
  return (
    <header>
      <img src={logo} alt="Arisa AI Logo" />
      <div className="logo">Arisa AI</div>
    </header>
  );
}

export default Header;
