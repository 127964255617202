import React from 'react';
import './testimonials.css';
function Testimonials() {
  return (
    <section class="testimonials">
			<h2>What Potential Users Are Saying</h2>
			<div class="testimonial-container">
				<div class="testimonial">
					<h3>Jyoti S</h3>
					<p>"I'm excited about Arisa! As someone who struggles with meal planning, the idea of an AI assistant that can suggest meals based on my preferences and health goals sounds amazing. It could really simplify my daily routine."</p>
				</div>
				<div class="testimonial">
					<h3>Sam A</h3>
					<p>"What intrigues me most about Arisa is its potential to expand into other areas like vacation planning. I often find myself overwhelmed when planning trips, so having an AI companion to assist would be incredibly helpful."</p>
				</div>
				<div class="testimonial">
					<h3>Venkata P</h3>
					<p>"As a busy professional, the concept of Arisa is very appealing. An AI assistant that can help with meal suggestions and potentially expand to shopping assistance could save me so much time and stress. I'm looking forward to seeing how it develops!"</p>
				</div>
			</div>
		</section>
  );
}

export default Testimonials;
