import React, { useState } from 'react';
import './overview.css';

function Overview() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <section className="hero">
        <h1>AI concierge: Your personal life optimizer</h1>
        <p>Arisa is a personalized AI friend that understands your needs, adapts to your lifestyle, and grows with you to enhance various aspects of your life</p>
        <button id="watchDemo" onClick={openModal}>Watch a Demo</button>
      </section>

      <div className="modal" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-content">
          <span className="close" onClick={closeModal}>&times;</span>
          <div className="video-container">
            <iframe 
              src="https://www.youtube.com/embed/HQGnd4fxuEM?modestbranding=1&rel=0&showinfo=0" 
              title="Arisa AI Demo"
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerPolicy="strict-origin-when-cross-origin" 
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
