import React from 'react';
import './keyfeatures.css';

function TailoredIcon() {
  return (
    <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
    </svg>
  );
}

function GrowthIcon() {
  return (
    <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clipRule="evenodd"></path>
    </svg>
  );
}

function IntegrationIcon() {
  return (
    <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path>
    </svg>
  );
}

function KeyFeatures() {
	return (
		<section className="key-features">
			<h2>Why Choose Arisa?</h2>
			<div className="feature-list">
				<div className="feature-item">
					<TailoredIcon />
					<h3 className="feature-title">Tailored Recommendations</h3>
					<p className="feature-description">Arisa learns your preferences to provide personalized suggestions across various aspects of your life.</p>
				</div>
				
				<div className="feature-item">
					<GrowthIcon />
					<h3 className="feature-title">Grows With You</h3>
					<p className="feature-description">As you interact with Arisa, it continuously adapts and expands its capabilities to meet your evolving needs.</p>
				</div>
				
				<div className="feature-item">
					<IntegrationIcon />
					<h3 className="feature-title">Seamless Integration</h3>
					<p className="feature-description">Arisa integrates effortlessly into your daily routine, becoming an indispensable part of your lifestyle.</p>
				</div>
			</div>
		</section>
	);
}

export default KeyFeatures;
