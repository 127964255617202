import React from 'react';
import './features.css';
import pizzaImage from './../../images/pizza.jpg';
import BiryaniImage from './../../images/biryani.jpg';
import BurritoImage from './../../images/burrito.jpg';

function Features() {
  return (
    <section className="features">
      <div className="feature">
        <img src={pizzaImage} alt="Pizza Suggestions" />
        <div className="feature-prompt">
          Craving a fusion of flavors? Your Butter Chicken Pizza is ready! Creamy, spicy, and oh-so-cheesy. Dive in now!
        </div>
      </div>
      <div className="feature">
        <img src={BiryaniImage} alt="Biryani Suggestions" />
        <div className="feature-prompt">
          Spice up your day with a fragrant bowl of Vegetable Biryani! Aromatic rice, fresh veggies, and the perfect blend of spices await.
        </div>
      </div>
      <div className="feature">
        <img src={BurritoImage} alt="Sushi Suggestions" />
        <div className="feature-prompt">
          It's time for a saucy delight! Your Burrito Mojado is here—packed, smothered, and ready to make your taste buds dance.
        </div>
      </div>
    </section>
  );
}

export default Features;
