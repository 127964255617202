import React from 'react';
import './App.css';
import Features from './components/features/features';
import KeyFeatures from './components/keyfeatures/keyfeatures';
import Testimonials from './components/testimonials/testimonials';
import Header from './components/header/header';
import Overview from './components/overview/overview';
import Footer from './components/footer/footer';

function App() {
  return (
    <div className="container">
      <Header />
      <Overview />
      <Features />
      <KeyFeatures />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;
