import React from 'react';
import './footer.css';
function Footer() {
  return (
    <footer>
        <p>&copy; 2024 Arisa AI. All rights reserved. | 
            <a href="/privacy.html" target="_blank" style={{ color: '#ffffff', textDecoration: 'underline', margin: '0px 5px' }}>Privacy Policy</a>
            |
            <a href="/support.html" target="_blank" style={{ color: '#ffffff', textDecoration: 'underline', margin: '0px 5px' }}>Support</a>
        </p>
    </footer>
  );
}

export default Footer;
